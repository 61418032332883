import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import TableHeader from '../../components/dbDisplay/moss-revive/tableHeader';
import TableRow from '../../components/dbDisplay/moss-revive/tableRow';
import * as RES from '../../helpers/responsive';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import Sticky from 'react-stickynode';
import mossOnBricks_img from '../../images/moss-revive/moss-on-bricks.jpg'
import mossInFlowers_img from '../../images/moss-revive/moss-in-flowers.jpg'
import mossOnWall_img from '../../images/moss-revive/moss-on-wall.jpg'
import mossOnWall1_img from '../../images/moss-revive/moss-on-wall1.jpg';



export default function MossRevive(props) {
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);
	const [mossRevive, setMossRevive] = useState({});
	const [mossRevivePriceLabels, setMossRevivePriceLabels] = useState({});

	const pdf_paths = {
		sds: '/PDF Collection/SDS Files/MossRevive/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('moss-revive/products');

		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();
				let newState = [];
				for (let item in items) {
					// console.log(items[item].physicalType);
					newState.push({
						id: item,
						sort: items[item].sort,
						partNo: items[item].partNo,
						name: items[item].name,
						price1: items[item].price1,
						price2: items[item].price2,
						price3: items[item].price3,
						price4: items[item].price4,
						light_shade: items[item].light_shade,
						medium_shade: items[item].medium_shade,
						heavy_shade: items[item].heavy_shade,
						has_sds: items[item].has_sds ? items[item].has_sds : false,
						sds_filename: items[item].sds_filename
							? items[item].sds_filename
							: '',
						date: items[item].date,
						notes: items[item].notes,
					});
				}
				newState.sort((a, b) => (a.sort - b.sort));
				setMossRevive(newState);
			}
		});
	}, []);

	useEffect(() => {
		const db = firebase.database();
		const refLabels = db.ref('moss-revive/labels');

		const unsubscribe = refLabels.on('value', (snapshot) => {
			if (snapshot) {
				setMossRevivePriceLabels(snapshot.val());
			}
		});
		return () => unsubscribe();
	}, []);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>Koch Color - Moss Revive</title>
				<meta
					name="description"
					content="Moss Revive is a specially formulated decorative coating created for fresh and faded mosses but can be used on many other floral products. Moss Revive uses transparent pigments as colorants rather than dyes to give superior light fastness (fade resistance) and retention of the texture of the moss.  A highly flexible polyvinyl acrylic resin serves as a binder holding the pigment in place.  This binder provides added protection to the moss, reducing crumbling associated with dried moss and other air-dried products. Moss Revive is a concentrated product and is typically diluted with water before use.  Dilution ranges from 3 to 9 parts water to each part Moss Revive (by volume).  Application methods are spray and immersion.  Spray uses less material and can be applied with a simple trigger sprayer or an air-driven paint sprayer. Application by spray allows for creative, artistic effects.  Dipping the moss in the solution cuts down on labor cost and provides total coverage.Dry time depends on dilution rate, application method, temperature, humidity and air movement. A light surface coating typically dries to the touch in 30-60 minutes. Immersion application may require 3 hours or more."
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box">
						<div className="title has-text-centered ">
							Moss Revive by Robert Koch Industries
						</div>
						<section>
							<div className="columns section">
								{/* <div className="column is-2"></div> */}
								<div className="column is-3">
									<div className="mossrevive-img">
										<img className="image" src={mossOnWall_img} alt="" />
									</div>
								</div>

								<div className="column is-3">
									<div className=" block mossrevive-img">
										<img className="image" src={mossInFlowers_img} alt="" />
									</div>
								</div>
								<div className="column is-3">
									<div className="mossrevive-img">
										<img className="imag" src={mossOnBricks_img} alt="" />
									</div>
								</div>
								<div className="column is-3">
									<div className="mossrevive-img">
										<img className="image" src={mossOnWall1_img} alt="" />
									</div>
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Description</div>
							<div className="block">
								Moss Revive is a specially formulated decorative coating created
								for fresh and faded mosses but can be used on many other floral
								products. Moss Revive uses transparent pigments as colorants
								rather than dyes to give superior light fastness (fade
								resistance) and retention of the texture of the moss. A highly
								flexible polyvinyl acrylic resin serves as a binder holding the
								pigment in place. This binder provides added protection to the
								moss, reducing crumbling associated with dried moss and other
								air-dried products.
							</div>
							<div className="block is-size-4">Application</div>
							<div>
								Moss Revive is a concentrated product and is typically diluted
								with water before use. Dilution ranges from 3 to 9 parts water
								to each part Moss Revive (by volume). Application methods are
								spray and immersion. Spray uses less material and can be applied
								with a simple trigger sprayer or an air-driven paint sprayer.
								Application by spray allows for creative, artistic effects.
								Dipping the moss in the solution cuts down on labor cost and
								provides total coverage. Dry time depends on dilution rate,
								application method, temperature, humidity and air movement. A
								light surface coating typically dries to the touch in 30-60
								minutes. Immersion application may require 3 hours or more.
							</div>
						</div>
					</div>
					<div className="box">
						<section className="">
							<Sticky enabled={true} top={100}>
								<TableHeader
									title="Moss Revive"
									priceLabels={mossRevivePriceLabels}
								/>
							</Sticky>
							{mossRevive && mossRevive.length > 0 ? (
								mossRevive.map((item) => {
									if (!searchId) {
										return (
											<TableRow
												{...item}
												key={item.id}
												priceLabels={mossRevivePriceLabels}
												pdf_paths={pdf_paths}
											/>
										);
									} else {
										if (item.id === searchId) {
											return (
												<>
													<TableRow
														{...item}
														key={item.id}
														priceLabels={mossRevivePriceLabels}
													/>
													<button
														className="button is-info is-small"
														onClick={onSearchClearClicked}
													>
														Clear Search Results
													</button>
												</>
											);
										}
										return null;
									}
								})
							) : (
								<progress className="progress is-small is-primary" max="100">
									15%
								</progress>
							)}
						</section>
					</div>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<></>
				<div className="">

					<div className="">
						<section className="">
							<Sticky enabled={true} top={100} innerZ="999">
								<TableHeader
									title="Moss Revive"
									priceLabels={mossRevivePriceLabels}
								/>
							</Sticky>
							{mossRevive && mossRevive.length > 0 ? (
								mossRevive.map((item) => {
									if (!searchId) {
										return (
											<TableRow
												{...item}
												key={item.id}
												priceLabels={mossRevivePriceLabels}
												pdf_paths={pdf_paths}
											/>
										);
									} else {
										if (item.id === searchId) {
											return (
												<>
													<TableRow
														{...item}
														key={item.id}
														priceLabels={mossRevivePriceLabels}
													/>
													<button
														className="button is-info is-small"
														onClick={onSearchClearClicked}
													>
														Clear Search Results
													</button>
												</>
											);
										}
										return null;
									}
								})
							) : (
								<progress className="progress is-small is-primary" max="100">
									15%
								</progress>
							)}
						</section>
					</div>
				</div>
			</RES.Handheld>
		</>
	);
}
