import React, { useState, useEffect } from 'react';
import firebase from '../../firebase/firebaseConfig';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import PDFButton from '../../components/dbDisplay/PDFButton';
import GeneralTable from '../../components/dbDisplay/dyes/generalTable';

import carnations_img from '../../images/dyes/floral-absorption/carnations 1.jpg';
import bluerose_img from '../../images/dyes/floral-absorption/blue_rose.jpg';
import rainbowrose_img from '../../images/dyes/floral-absorption/rainbow_rose.jpg';
import sunflowers_img from '../../images/dyes/floral-absorption/sunflowers.jpg';
import { useHistory } from 'react-router-dom';
import * as RES from '../../helpers/responsive';

export default function FloralAbsorption(props) {
	const [dyes, setDyes] = useState({});
	const [filteredDyes, setFilteredDyes] = useState({});
	const history = useHistory();
	const [searchId, setSearchId] = useState(null);

	const filters = {
		physicalForms: {
			powder: false,
			liquid: false,
			granular: false,
		},
		colors: {
			red: false,
			green: false,
			blue: false,
			yellow: false,
			orange: false,
			violet: false,
			brown: false,
			black: false,
		},
	};

	const pdf_paths = {
		tds: '/PDF Collection/2000-TDS/',
		sds: '/PDF Collection/SDS Files/2000-SDS/',
	};

	useEffect(() => {
		const ref = firebase.database().ref('dyes/general');
		ref.on('value', (snapshot) => {
			if (snapshot.exists()) {
				let items = snapshot.val();

				let newState = [];
				for (let item in items) {
					if (items[item].floral) {
						newState.push({
							id: item,
							partNo: items[item].RKIPartNo,
							name: items[item].name,
							lightShade: items[item].lightShade,
							darkShade: items[item].darkShade,
							physicalType: items[item].physicalType,
							price_1lb_Jar: items[item].price_1lb_Jar,
							price_5lb_Jar: items[item].price_5lb_Jar,
							price_25gal_Pail: items[item].price_25gal_Pail,
							price_small_drum: items[item].price_small_drum,
							price_large_drum: items[item].price_large_drum,
							primaryColor: items[item].primaryColor,
							primaryColorOrder: items[item].primColorOrder,
							// formerTradeNames: items[item].formerTradeNames,
							fluorescent: items[item].fluorescent
								? items[item].fluorescent
								: false,
							has_link: items[item].has_link ? items[item].has_link : false,
							link_url: items[item].link_url ? items[item].link_url : '',
							has_sds: items[item].has_sds ? items[item].has_sds : false,
							sds_filename: items[item].sds_filename
								? items[item].sds_filename
								: false,
							has_sds_es: items[item].has_sds_es
								? items[item].has_sds_es
								: false,
							sds_filename_es: items[item].sds_filename_es
								? items[item].sds_filename_es
								: false,
							inactive: items[item].inactive ? items[item].inactive : false,
						});
					}
				}
				newState.sort((a, b) => (a.primaryColor < b.primaryColor ? 1 : -1));
				setDyes(newState);
				setFilteredDyes(newState);
			}
		});
	}, []);

	// filter color and physical forms. object of filter keys passed into function
	const onFilterClicked = (newFilterKeys) => {
		//filter by form first, then by colors.
		if (dyes.length) {
			// if no filters selected show all dyes
			if (
				newFilterKeys['colors'].length < 1 &&
				newFilterKeys['physicalForms'].length < 1
			) {
				setFilteredDyes(dyes);
				return;
			}

			//filter by form
			let filteredPhysicalFormDyes = [];
			if (newFilterKeys['physicalForms'].length < 1) {
				filteredPhysicalFormDyes = [...dyes];
			} else {
				filteredPhysicalFormDyes = dyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['physicalForms'].forEach((filterkey) => {
						if (dye.physicalType.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			}

			// WE NEED TO FILTER THE COLORS FROM THE RESULTS OF THE FORM FILTER, NOT FROM ALL DYES

			// //filter by color
			let filteredColorDyes = [];
			if (newFilterKeys['colors'].length > 0) {
				filteredColorDyes = filteredPhysicalFormDyes.filter((dye) => {
					// return true if match
					let found = false;
					newFilterKeys['colors'].forEach((filterkey) => {
						if (dye.primaryColor.toLowerCase().includes(filterkey)) {
							found = true;
						}
						return true;
					});
					return found;
				});
			} else {
				filteredColorDyes = filteredPhysicalFormDyes.concat(filteredColorDyes);
			}

			setFilteredDyes(filteredColorDyes);
		}
	};

	useEffect(() => {
		if (props.location.hash && props.location.hash.length > 1) {
			let id = props.location.hash.slice(1);
			setSearchId(id);
		} else {
			setSearchId(null);
		}
	}, [props.location.hash]);

	const onSearchClearClicked = () => {
		history.push(props.location.pathName);
	};

	return (
		<>
			<Helmet>
				<title>Koch Color - Absorption Dyes for Fresh Flowers</title>
				<meta
					name="description"
					content="Absorption dyes are specially selected anionic dyes used to systemically dye fresh cut flowers and foliage. These water soluble dyes are primarily used in two applications: There are a number of applications for these dyes, such as: dyeing fresh cut flowers that will be sold on the fresh flower market. dyeing fresh flowers and foliage that will be sold as a dried or preserved product."
				/>
			</Helmet>
			<RES.Desktop>
				<div className="section pt-5">
					<div className="box">
						<div className="title has-text-centered ">
							Absorption Dyes for Fresh Flowers
						</div>
						<section className="container">
							<div className="columns">
								<div className="column is-3">
									<img className="image ob" src={carnations_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={bluerose_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={rainbowrose_img} alt="" />
								</div>
								<div className="column is-3">
									<img className="image ob" src={sunflowers_img} alt="" />
								</div>
							</div>
						</section>
						<div className="section">
							<div className="block is-size-4">Dyeing Fresh Cut Flowers</div>
							<div className="block">
								Absorption dyes are specially selected anionic dyes used to
								systemically dye fresh cut flowers and foliage. These water
								soluble dyes are primarily used in two applications:
							</div>
							<div className="content">
								There are a number of applications for these dyes, such as :
								<ul>
									<li>
										dyeing fresh cut flowers that will be sold on the fresh
										flower market.
									</li>
									<li>
										dyeing fresh flowers and foliage that will be sold as a
										dried or preserved product.
									</li>
								</ul>
							</div>
							<div className="block">
								In the latter case, the dyes are either used with glycerine
								preservatives to dye flowers and foliage while they are being
								preserved or are used to dye fresh flowers and foliage after
								harvest before being air dried. When dyeing fresh cut flowers,
								the dyes are generally used at the rate of 1-2 ounces per gallon
								(8-16 grams per liter) of water. One pound of dye tints
								approximately 5,000-10,000 stems. In addition, these dyes are
								also suitable for systemically dyeing fresh cut flowers or
								foliage immediately after harvest before being air-dried. Our
								absorption floral dyes can also be added to glycerine-based
								systemic preserving solutions to color eucalyptus, gypsophila,
								sinuata statice, etc. during the preserving process. General use
								rates are 0.25-0.75 ounces per gallon, (2-6 grams per liter),
								depending on depth of shade desired.
							</div>
							<div className="block is-size-4">Surfactants</div>
							<div className="block">
								<strong>
									Absorption dyes generally require the use of a wetting agent
									(surfactant) to facilitate uptake.{' '}
								</strong>
								This is especially true for dyeing fresh cut flowers. In most
								cases the addition of a wetting agent also benefits glycerine
								preservative solutions. Thorough experimentation is recommended
								prior to production. We recommend our TW-20 Systemic Wetting
								Agent. See our Wetting Agents page for information regarding
								wetting agents.
							</div>
							<div className="has-text-centered">
								<Link
									className="button is-success"
									to="/dyes/floral-and-craft-absorption/wetting-agents"
								>
									Wetting Agents
								</Link>
							</div>
							<div className="block is-size-4">Technical Bulletins</div>
							<div className="block">
								<p className="block">
									Complete information regarding the use of absorption floral
									dyes is available in the following technical bulletins and
									should be consulted prior to use.
								</p>
								<div className="block">
									<div className="bbuttons">
										<p className="">Product Reference Sheet #101</p>
										<PDFButton
											className="block mt-6"
											buttonText="Systemically Dyeing Fresh Cut Flowers"
											link={
												process.env.PUBLIC_URL +
												'/PDF Collection/technical-bulletins/ref101-dyeing-fresh-flowers-and-foliage-rs.pdf'
											}
										></PDFButton>
										<p className="mt-4">Hoja de Referencia del Producto #101</p>
										<PDFButton
											className="block mt-6"
											buttonText="Tinturación de Flores Cortadas con Tinturas Florales de Absorción"
											link={
												process.env.PUBLIC_URL +
												'/PDF Collection/technical-bulletins/ref-101s-espanol-v3.pdf'
											}
										></PDFButton>
										<p className="mt-4">
											Preview Product Reference Sheet #420 (entire document is
											available for purchase for $40 by{' '}
											<Link to="/contact">contacting us</Link>)
										</p>

										<PDFButton
											className="block mt-6"
											buttonText="Preserving Flowers & Foliage"
											link={
												process.env.PUBLIC_URL +
												'/PDF Collection/technical-bulletins/ref-420v8-WEB.pdf'
											}
										></PDFButton>
										<p className="mt-4">
											Preview Product Reference Sheet #421 (entire document is
											available for purchase for $89.95 by{' '}
											<Link to="/contact">contacting us</Link>)
										</p>

										<PDFButton
											className="block mt-6"
											buttonText="Preserving Flowers & Foliage for the Commercial User"
											link={
												process.env.PUBLIC_URL +
												'/PDF Collection/technical-bulletins/ref-421-cover-and-contents.pdf'
											}
										></PDFButton>
									</div>
								</div>
							</div>
						</div>
					</div>
					<GeneralTable
						filteredDyes={filteredDyes}
						filterColors={filters.colors}
						// filterPhysicalForms={filters.physicalForms}
						displayPhysicalForm={false}
						onFilterClicked={(value) => onFilterClicked(value)}
						title="Absorption Dyes for Fresh Flowers"
						noTDS={false}
						noSDS={false}
						onSearchClear={onSearchClearClicked}
						searchId={searchId}
						pdf_paths={pdf_paths}
						floralAbsorption={true}
						noLiquid={true}
					/>
				</div>
			</RES.Desktop>
			<RES.Handheld>
				<GeneralTable
					filteredDyes={filteredDyes}
					filterColors={filters.colors}
					// filterPhysicalForms={filters.physicalForms}
					displayPhysicalForm={false}
					onFilterClicked={(value) => onFilterClicked(value)}
					title="Absorption Dyes for Fresh Flowers"
					noTDS={false}
					noSDS={false}
					onSearchClear={onSearchClearClicked}
					searchId={searchId}
					pdf_paths={pdf_paths}
					floralAbsorption={true}
					noLiquid={true}
				/>
			</RES.Handheld>
		</>
	);
}
